// we need to import css files here as otherwise next throws an error on building

import "@fontsource/inter/200.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "antd/dist/antd.css";

import "@/components/application/ant-customizations.css";
import "@/components/application/main.css";

export {default} from "@/components/application";
