import camelcaseKeys from "camelcase-keys";
import {useEffect, useState} from "react";

import type {components} from "./deal-room-api-schema";
import useDealRoomApiGet from "./use-deal-room-api-get";

type DirectoryFolder = components["schemas"]["DirectoryFolder"];
type DirectoryItem = components["schemas"]["DirectoryItem"];
type DirectoryFilter = components["schemas"]["DealDirectoryFilter"];

type Params = {
  currentFolder?: DirectoryFolder;
  dealId: number;
  filter?: DirectoryFilter;
};

const useDirectory = ({currentFolder, dealId, filter}: Params) => {
  const [currentDirectory, setCurrentDirectory] = useState<DirectoryItem[]>([]);

  const [shouldRevalidate, setShouldRevalidate] = useState(false);

  const directoryResponse = useDealRoomApiGet(
    "/deal/{deal-id}/directory",
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "deal-id": dealId,
      path: currentFolder?.path,
      filter
    },
    {
      revalidateIfStale: shouldRevalidate,
      revalidateOnFocus: false
    }
  );

  useEffect(() => {
    setShouldRevalidate(true);
    return () => setShouldRevalidate(false);
  }, [currentFolder]);

  useEffect(() => {
    const directory =
      directoryResponse.data?.data &&
      camelcaseKeys(directoryResponse.data.data, {deep: true});

    if (directory) {
      setCurrentDirectory(directory);
    }
  }, [directoryResponse.data]);

  const isFetchingDirectory = directoryResponse.data === undefined;

  return {
    currentDirectory,
    refreshDirectory: directoryResponse.mutate,
    isFetchingDirectory
  };
};

export default useDirectory;
