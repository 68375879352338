import auth0Client from "../auth0/auth0-client";
import environmentVariables from "../environment/environment-variables";
import {uploadFile} from "../http";

import type {components} from "./deal-room-api-schema";

type UploadFileResult = components["schemas"]["UploadFileToStagingResponse"];

const getHeaders = (accessToken: string) => ({
  Authorization: "Bearer " + accessToken
});

const getUploadFileUrl = (endpoint: string) =>
  environmentVariables.DEAL_ROOM_API_URL + endpoint;

const dealRoomApiUploadFile = async (
  endpoint: string,
  file: Blob
): Promise<UploadFileResult> => {
  const accessToken = await auth0Client.getTokenSilently();

  return uploadFile({
    method: "POST",
    headers: getHeaders(accessToken),
    params: {file},
    url: getUploadFileUrl(endpoint)
  }).then(response => response.json());
};

export default dealRoomApiUploadFile;
