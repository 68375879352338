import * as withOpenApiClient from "@unlockre/open-api-client/dist";

import auth0Client from "../auth0/auth0-client";
import environmentVariables from "../environment/environment-variables";

import type {paths} from "./deal-room-api-schema";

const dealRoomApiClient = withOpenApiClient.create<paths>({
  baseUrl: environmentVariables.DEAL_ROOM_API_URL,
  use: [withOpenApiClient.createAuth0Middleware(auth0Client)]
});

export default dealRoomApiClient;
