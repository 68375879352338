import {getResponsiveCss} from "@unlockre/components-library/dist/theme-provider/theme";
import {themeBreakpointNames} from "@unlockre/components-library/dist/theme-provider/theme/theme-breakpoint-name";
import {css} from "styled-components";

const privatePageHorizontalPaddings = {
  [themeBreakpointNames.desktop]: 96,
  [themeBreakpointNames.laptop]: 48,
  [themeBreakpointNames.tablet]: 24
};

const privatePageHorizontalPaddingCss = css`
  padding-left: ${privatePageHorizontalPaddings.desktop}px;
  padding-right: ${privatePageHorizontalPaddings.desktop}px;

  ${getResponsiveCss(
    "laptop",
    css`
      padding-left: ${privatePageHorizontalPaddings.laptop}px;
      padding-right: ${privatePageHorizontalPaddings.laptop}px;
    `
  )}

  ${getResponsiveCss(
    "tablet",
    css`
      padding-left: ${privatePageHorizontalPaddings.tablet}px;
      padding-right: ${privatePageHorizontalPaddings.tablet}px;
    `
  )}
`;

export {privatePageHorizontalPaddings, privatePageHorizontalPaddingCss};
