import ButtonDropdown from "@unlockre/components-library/dist/button-dropdown/button-dropdown";
import {useRouter} from "next/router";
import {useEffect} from "react";

import useStore from "@/store/use-store";
import * as withPropertyTypesFilters from "@/utils/deal-room-api/property-type-filter";
import {useUserSplitIoTreatments} from "@/utils/split-io";

import {usePropertyTypeFilterEvent} from "./property-type-filter-event";

const {propertyTypeFilterOptions, propertyTypeFilters} =
  withPropertyTypesFilters;

const featureFlagNames = ["showNNNPropertyFilter"] as const;

const PropertyTypeFilterDropdown = () => {
  const userSplitIoTreatments = useUserSplitIoTreatments(featureFlagNames);

  const [propertyTypeFilter, setPropertyTypeFilter] = useStore(state => [
    state.propertyTypeFilter,
    state.setPropertyTypeFilter
  ]);

  useEffect(() => {
    if (userSplitIoTreatments?.showNNNPropertyFilter?.treatment !== "on") {
      setPropertyTypeFilter(propertyTypeFilters.multifamily);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackPropertyTypeFilterEvent = usePropertyTypeFilterEvent();

  const router = useRouter();

  const isPipelineScreen = router.pathname === "/pipeline";

  const items = isPipelineScreen
    ? propertyTypeFilterOptions.filter(
        propertyType => propertyType !== propertyTypeFilters.all
      )
    : propertyTypeFilterOptions;

  return (
    <ButtonDropdown
      disabled={userSplitIoTreatments?.showNNNPropertyFilter.treatment !== "on"}
      getItemLabel={withPropertyTypesFilters.getLabel}
      items={items}
      onChange={newPropertyTypeFilter => {
        setPropertyTypeFilter(newPropertyTypeFilter);
        trackPropertyTypeFilterEvent({
          type: "propertyTypeFilterChange",
          data: {
            dealType: newPropertyTypeFilter,
            location: window.location.href
          }
        });
      }}
      size="large"
      value={propertyTypeFilter}
      variant="transparent"
    />
  );
};

export default PropertyTypeFilterDropdown;
