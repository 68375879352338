import type {SWRResponse} from "swr";

import type {SWRLikeHook} from "./types";

type SwrResponseProcessor<TResult, TData, TError = Error> = (
  swrResponse: SWRResponse<TData, TError>
) => TResult;

const swrWithCustomResponse =
  <TResult, TArgs extends unknown[], TData, TError = Error>(
    swrLikeHook: SWRLikeHook<TArgs, TData, TError>,
    processSwrResponse: SwrResponseProcessor<TResult, TData, TError>
  ) =>
  (...args: TArgs) =>
    processSwrResponse(swrLikeHook(...args));

export default swrWithCustomResponse;
