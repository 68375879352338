import styled from "styled-components";

import {privatePageHorizontalPaddingCss} from "./private-page-horizontal-padding-css";

const PrivatePageContainer = styled.div`
  ${privatePageHorizontalPaddingCss}

  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 32px;
  padding-bottom: 32px;
  position: relative;
`;

export default PrivatePageContainer;
