import camelcaseKeys from "camelcase-keys";
import {useMemo} from "react";

import useDealRoomApiGet from "./use-deal-room-api-get";
import type {UseDealRoomApiGetOptions} from "./use-deal-room-api-get";

const getDealEndpoint = "/deal/{deal-id}";

type UseDealOptions = UseDealRoomApiGetOptions<typeof getDealEndpoint>;

const useDeal = (dealId?: number, options?: UseDealOptions) => {
  const dealResponse = useDealRoomApiGet(
    getDealEndpoint,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "deal-id": dealId ?? 0
    },
    {...options, skip: dealId === undefined || options?.skip}
  );

  const deal = useMemo(
    () =>
      dealResponse.data?.data &&
      camelcaseKeys(dealResponse.data.data, {deep: true}),
    [dealResponse.data]
  );

  return {deal, refreshDeal: dealResponse.mutate};
};

export default useDeal;

export type {UseDealOptions};
