import camelcaseKeys from "camelcase-keys";
import {useMemo} from "react";

import useDealRoomApiGet from "./use-deal-room-api-get";
import type {UseDealRoomApiGetOptions} from "./use-deal-room-api-get";

const getTaskEndpoint = "/task/{task-id}";

type UseTaskOptions = UseDealRoomApiGetOptions<typeof getTaskEndpoint>;

const useTask = (taskId: number, options?: UseTaskOptions) => {
  const taskResponse = useDealRoomApiGet(
    getTaskEndpoint,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "task-id": taskId
    },
    options
  );

  const task = useMemo(
    () =>
      taskResponse.data?.data &&
      camelcaseKeys(taskResponse.data.data, {deep: true}),
    [taskResponse.data]
  );

  return {task, refreshTask: taskResponse.mutate};
};

export default useTask;
