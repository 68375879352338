import {useRef} from "react";

type Params<TResponse> = {
  intervalMs: number;
  shouldContinue: (response: TResponse, retriesCount: number) => boolean;
};

const useLimitedRefreshInterval = <TResponse>({
  intervalMs,
  shouldContinue
}: Params<TResponse>) => {
  const retriesCountRef = useRef(1);

  const refreshInterval = (response: TResponse) => {
    if (!shouldContinue(response, retriesCountRef.current)) {
      return 0;
    }

    retriesCountRef.current++;

    return intervalMs;
  };

  return refreshInterval;
};

export default useLimitedRefreshInterval;
