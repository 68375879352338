import {useAuth0} from "@auth0/auth0-react";
import camelcaseKeys from "camelcase-keys";

import useDealRoomApiGet from "./use-deal-room-api-get";

const useIsUserDealMember = (dealId: number) => {
  const {user} = useAuth0();

  const dealMembersResponse = useDealRoomApiGet(
    "/deal/{deal-id}/members",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    {"deal-id": dealId}
  );

  const dealMembers =
    dealMembersResponse.data?.data &&
    camelcaseKeys(dealMembersResponse.data?.data, {deep: true});

  return (
    user && dealMembers?.some(dealMember => dealMember.email === user.email)
  );
};

export default useIsUserDealMember;
