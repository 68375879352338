import {useAuth0} from "@auth0/auth0-react";
import camelcaseKeys from "camelcase-keys";
import {useMemo} from "react";

import * as withMember from "./member";
import useDealRoomApiGet from "./use-deal-room-api-get";
import type {UseDealRoomApiGetOptions} from "./use-deal-room-api-get";

const getDealMembersEndpoint = "/deal/{deal-id}/members";

type UseDealMembersOptions = UseDealRoomApiGetOptions<
  typeof getDealMembersEndpoint
>;

const useDealMembers = (dealId: number, options?: UseDealMembersOptions) => {
  const {user} = useAuth0();

  const dealMembersResponse = useDealRoomApiGet(
    getDealMembersEndpoint,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "deal-id": dealId
    },
    options
  );

  const dealMembers = useMemo(
    () =>
      dealMembersResponse.data?.data &&
      camelcaseKeys(dealMembersResponse.data.data, {deep: true}),
    [dealMembersResponse.data]
  );

  const isUserDealMember = () => {
    const buyerMembers = dealMembers && withMember.getBuyerMembers(dealMembers);

    const userDealMember = buyerMembers?.find(
      buyerMember => buyerMember.email === user?.email
    );

    return userDealMember;
  };

  return {
    dealMembers,
    refreshDealMembers: dealMembersResponse.mutate,
    isUserDealMember
  };
};

export default useDealMembers;
