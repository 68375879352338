import useDealRoomApiGet from "./use-deal-room-api-get";
import type {UseDealRoomApiGetOptions} from "./use-deal-room-api-get";

const getDealFileEndpoint = "/deal/{deal-id}/files/{file-id}";

type UseDealFileOptions = UseDealRoomApiGetOptions<typeof getDealFileEndpoint>;

type Params = {
  dealId: number;
  fileId: string;
  fileName?: string;
  options?: UseDealFileOptions;
};

const useFileInfo = ({dealId, fileId, fileName, options}: Params) => {
  const response = useDealRoomApiGet(
    getDealFileEndpoint,
    /* eslint-disable @typescript-eslint/naming-convention */
    {
      "deal-id": dealId,
      "file-id": fileId,
      fileName
    },
    /* eslint-enable */
    options
  );

  const file = response?.data?.data;

  return file;
};

export default useFileInfo;
