import type {SWRResponse} from "swr";

const throwIfError = <TData, TError = Error>({
  error,
  ...rest
}: SWRResponse<TData, TError>) => {
  if (error) {
    throw error;
  }

  return rest;
};

export {throwIfError};
