import snakecaseKeys from "snakecase-keys";

import type {Lease} from "./types";
import useDealRoomApiMutate from "./use-deal-room-api-mutate";

const useLeaseUpdate = (dealId: number) => {
  const dealRoomApiMutate = useDealRoomApiMutate();

  const leaseUpdate = (input: Lease) =>
    dealRoomApiMutate("/deal/{deal-id}/lease", "patch", {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "deal-id": dealId,
      ...snakecaseKeys(input)
    });

  return leaseUpdate;
};

export default useLeaseUpdate;
