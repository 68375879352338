import useLease from "./use-lease";
import type {UseLeaseOptions} from "./use-lease";
import useLeaseUpdate from "./use-lease-update";

const useLeaseWithUpdate = (dealId: number, options?: UseLeaseOptions) => {
  const {lease, refreshLease} = useLease(dealId, options);

  const leaseUpdate = useLeaseUpdate(dealId);

  const updateLease: typeof leaseUpdate = input =>
    leaseUpdate(input).then(response => {
      refreshLease();

      return response;
    });

  return {lease, updateLease};
};

export default useLeaseWithUpdate;
