import {PageRenderer} from "@unlockre/page-tools/dist";
import type {PageWithLayoutAppProps} from "@unlockre/page-tools/dist";
import {useTrackAuth0User} from "@unlockre/utils-amplitude/dist";
import {keywayPermissions} from "@unlockre/utils-auth0/dist/keyway-permission";
import {useInitUserSplitIoAttributes} from "@unlockre/utils-split-io/dist";
import {useUserback} from "@unlockre/utils-userback/dist";

import environmentVariables from "@/utils/environment/environment-variables";

import PageHead from "./page-head";
import PrivatePageLayout from "./private-page-layout";

type Props = {
  appProps: PageWithLayoutAppProps;
};

const publicRoutePaths = ["/activate-account"];

const Page = (props: Props) => {
  useTrackAuth0User();

  useInitUserSplitIoAttributes();

  useUserback({userbackApiKey: environmentVariables.USERBACK_ACCESS_TOKEN});

  return (
    <>
      <PageHead />
      <PageRenderer
        {...{publicRoutePaths}}
        {...props}
        accessPermission={keywayPermissions.keywayAccessDealRoomAdmin}
        DefaultPrivatePageLayout={PrivatePageLayout}
      />
    </>
  );
};

export default Page;
