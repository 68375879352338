import {useTrackAnyEvent} from "@unlockre/utils-amplitude/dist";
import type {AbstractEvent} from "@unlockre/utils-amplitude/dist";

import type {PropertyTypeFilter} from "@/utils/deal-room-api/property-type-filter";

type PropertyTypeFilterChangeEvent = AbstractEvent<
  "propertyTypeFilterChange",
  {
    dealType: PropertyTypeFilter;
    location: string;
  }
>;

type PropertyTypeFilterEvent = PropertyTypeFilterChangeEvent;

const usePropertyTypeFilterEvent = useTrackAnyEvent<PropertyTypeFilterEvent>;

export {usePropertyTypeFilterEvent};
