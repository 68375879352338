import {useAnyOpenApiClient} from "@unlockre/open-api-client/dist/open-api-clients-context";

import estimateApiName from "./estimate-api-name";
import type {EstimateApiClient} from "./types";

const useEstimateApiClient = <
  TOpenApiClientRecord extends {[estimateApiName]: EstimateApiClient}
>() =>
  useAnyOpenApiClient<TOpenApiClientRecord, typeof estimateApiName>(
    estimateApiName
  );

export default useEstimateApiClient;
