import type {CamelCaseKeys} from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import type {components} from "./deal-room-api-schema";
import useDealRoomApiMutate from "./use-deal-room-api-mutate";

type PropertyUpdateInput = CamelCaseKeys<
  components["schemas"]["UpdatePropertyRequest"],
  true
>;

const usePropertyUpdate = (propertyId?: number) => {
  const dealRoomApiMutate = useDealRoomApiMutate();

  const propertyUpdate = (input: PropertyUpdateInput) => {
    if (!propertyId) {
      throw new Error("propertyId is not defined");
    }

    return dealRoomApiMutate("/properties/{property-id}", "put", {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "property-id": String(propertyId),
      ...snakecaseKeys(input)
    });
  };

  return propertyUpdate;
};

export default usePropertyUpdate;
