import * as z from "zod";

import type {components} from "./deal-room-api-schema";
import {dealTeams} from "./deal-team";
import {memberTypeSchema, memberTypes} from "./member-type";

type Member = components["schemas"]["MemberDto"];

const memberSchema = z.object({
  id: z.number(),
  type: memberTypeSchema,
  team: z.enum([dealTeams.buyer, dealTeams.seller]),
  organizationId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  companyName: z.string(),
  address: z.string().optional(),
  phoneNumber: z.string().optional(),
  email: z.string(),
  createdAt: z.string(),
  updatedAt: z.string()
});

const isSellerBroker = (member: Member) =>
  member.type === memberTypes.sellerBroker;

const isSeller = (member: Member) => member.type === memberTypes.seller;

const getFullName = (member: Member) =>
  member.firstName + " " + member.lastName;

const getSellerMembers = (members: Member[]) =>
  members.filter(member => member.team === memberTypes.seller);

const getBuyerMembers = (members: Member[]) =>
  members.filter(member => member.team === memberTypes.buyer);

const getCompanyName = (member: Member) => member.companyName;

export {
  getBuyerMembers,
  getCompanyName,
  getFullName,
  getSellerMembers,
  isSeller,
  isSellerBroker,
  memberSchema
};
