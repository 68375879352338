import NextHead from "next/head";

const PageHead = () => (
  <NextHead>
    <title>DealRoom</title>
    <meta
      content="width=device-width, initial-scale=1, maximum-scale=1"
      name="viewport"
    />
    <link href="/favicon.ico" rel="icon" />
  </NextHead>
);

export default PageHead;
