import type {components} from "./deal-room-api-schema";
import {isPropertyType} from "./property-type";

type SearchableEnumPropertyType =
  components["schemas"]["SearchableEnumPropertyType"];

type PropertyTypeQueryFilter = {
  propertyType: SearchableEnumPropertyType | null;
};

const propertyTypeFilters = {
  medical: "MEDICAL",
  multifamily: "MULTIFAMILY",
  all: "ALL"
} as const;

type PropertyTypeFilter =
  (typeof propertyTypeFilters)[keyof typeof propertyTypeFilters];

const propertyTypeFilterLabels = {
  [propertyTypeFilters.medical]: "Medical Office",
  [propertyTypeFilters.multifamily]: "Multifamily",
  [propertyTypeFilters.all]: "All deals"
};

const propertyTypeFilterOptions = [
  propertyTypeFilters.medical,
  propertyTypeFilters.multifamily,
  propertyTypeFilters.all
];

const getLabel = (propertyType: PropertyTypeFilter) =>
  propertyTypeFilterLabels[propertyType];

const getQueryFilter = (
  propertyTypeFilter: PropertyTypeFilter
): PropertyTypeQueryFilter => ({
  propertyType: isPropertyType(propertyTypeFilter)
    ? {
        operator: "EQ",
        values: [propertyTypeFilter]
      }
    : null
});

export {
  getLabel,
  getQueryFilter,
  propertyTypeFilters,
  propertyTypeFilterOptions
};

export type {PropertyTypeFilter};
