import {
  getColorByAlias,
  getResponsiveCss,
  getTypography,
  useThemeBreakpoint
} from "@unlockre/components-library/dist/theme-provider/theme";
import {
  UnstyledAnchor,
  UnstyledUl
} from "@unlockre/components-library/dist/unstyled";
import Link from "next/link";
import {useRouter} from "next/router";
import styled, {css} from "styled-components";

import NavBar from "@/components/nav-bar";
import {useUserSplitIoTreatments} from "@/utils/split-io";

import {privatePageHorizontalPaddings} from "./private-page-horizontal-padding-css";
import PropertyTypeFilterDropdown from "./property-type-filter-dropdown";

type LinkItemStyledProps = {
  $isActive?: boolean;
};

const featureFlagNames = ["showLeads"] as const;

const propertyTypeFilterAllowedPaths = ["/", "/pipeline", "/calendar"];

const LinkList = styled(UnstyledUl)`
  display: flex;
  gap: 40px;

  ${getResponsiveCss(
    "tablet",
    css`
      overflow-x: auto;
      padding-left: ${privatePageHorizontalPaddings.tablet}px;
      padding-right: ${privatePageHorizontalPaddings.tablet}px;
    `
  )}
`;

const activeLinkItemCss = css`
  color: ${getColorByAlias("accentPrimary")};
  box-shadow: inset 0 -3px 0 0 ${getColorByAlias("accentPrimary")};
`;

const LinkItem = styled(UnstyledAnchor)<LinkItemStyledProps>`
  ${getTypography("body", "s")}

  color: ${getColorByAlias("textPrimary")};
  padding: 20px 0;
  display: inline-block;
  transition:
    box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover {
    ${activeLinkItemCss}
  }

  ${({$isActive}) => $isActive && activeLinkItemCss}
`;

const PropertyTypeFilterDropdownContainer = styled.div`
  ${getResponsiveCss(
    "tablet",
    css`
      align-self: end;
      padding-top: 10px;
      padding-right: 10px;
    `
  )}
`;

const PrivatePageNavBar = () => {
  const userSplitIoTreatments = useUserSplitIoTreatments(featureFlagNames);
  const router = useRouter();
  const isViewportWiderThanTablet = !useThemeBreakpoint("tablet");

  return (
    <NavBar>
      <LinkList>
        <Link href="/" passHref>
          <LinkItem $isActive={router.pathname === "/"}>Home</LinkItem>
        </Link>

        <Link href="/pipeline" passHref>
          <LinkItem $isActive={router.pathname === "/pipeline"}>
            Pipeline
          </LinkItem>
        </Link>

        {userSplitIoTreatments?.showLeads?.treatment === "on" && (
          <Link href="/estimates/list" passHref>
            <LinkItem $isActive={router.pathname === "/estimates/list"}>
              Leads
            </LinkItem>
          </Link>
        )}

        {isViewportWiderThanTablet && (
          <Link href="/calendar" passHref>
            <LinkItem $isActive={router.pathname === "/calendar"}>
              Calendar
            </LinkItem>
          </Link>
        )}

        <Link href="/members/list" passHref>
          <LinkItem $isActive={router.pathname === "/members/list"}>
            Members
          </LinkItem>
        </Link>
      </LinkList>

      {propertyTypeFilterAllowedPaths.includes(router.pathname) && (
        <PropertyTypeFilterDropdownContainer>
          <PropertyTypeFilterDropdown />
        </PropertyTypeFilterDropdownContainer>
      )}
    </NavBar>
  );
};

export default PrivatePageNavBar;
