import useDeal from "./use-deal";
import useDealRoomApiGet from "./use-deal-room-api-get";
import type {UseDealRoomApiGetOptions} from "./use-deal-room-api-get";

const getDealSchemaEndpoint = "/deal-schemas/{schema-id}";

type Options = UseDealRoomApiGetOptions<typeof getDealSchemaEndpoint>;

const useDealSchema = (dealId: number, options?: Options) => {
  const {deal} = useDeal(dealId, {
    revalidateIfStale: false,
    revalidateOnFocus: false
  });

  const dealSchemaResponse = useDealRoomApiGet(
    getDealSchemaEndpoint,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "schema-id": deal?.dealSchemaId as number
    },
    {
      skip: !deal,
      ...options
    }
  );

  return dealSchemaResponse.data?.data;
};

export default useDealSchema;
