import {
  getColor,
  getColorByAlias,
  getResponsiveCss
} from "@unlockre/components-library/dist/theme-provider/theme";
import styled, {css} from "styled-components";

import {privatePageHorizontalPaddingCss} from "../page/private-page-horizontal-padding-css";

const NavBar = styled.nav`
  ${privatePageHorizontalPaddingCss}

  align-items: center;
  background-color: ${getColorByAlias("backgroundWhite")};
  border-bottom: 1px solid ${getColor("gray", "070")};
  display: flex;
  gap: 20px;
  justify-content: space-between;

  ${getResponsiveCss(
    "tablet",
    css`
      flex-direction: column-reverse;
      gap: 10px;
      padding-left: 0;
      padding-right: 0;
      align-items: normal;
    `
  )}
`;

export default NavBar;
