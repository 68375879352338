import {keywayPermissions} from "@unlockre/utils-auth0/dist/keyway-permission";

import {dealRoomApiPermissions} from "../deal-room-api/deal-room-api-permission";

const dealRoomPermissions = {
  ...keywayPermissions,
  ...dealRoomApiPermissions
} as const;

type DealRoomPermission =
  (typeof dealRoomPermissions)[keyof typeof dealRoomPermissions];

export {dealRoomPermissions};

export type {DealRoomPermission};
