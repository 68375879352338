import {bindUseOpenApiGet} from "@unlockre/open-api-client/dist";
import type {
  OpenApiPathWith,
  UseOpenApiGetOptions
} from "@unlockre/open-api-client/dist";

import {swrWithCustomResponse} from "../swr";
import * as withSwrResponse from "../swr/swr-response";

import type {paths} from "./deal-room-api-schema";
import useDealRoomApiClient from "./use-deal-room-api-client";

const useDealRoomApiGet = swrWithCustomResponse(
  bindUseOpenApiGet(useDealRoomApiClient),
  withSwrResponse.throwIfError
);

type UseDealRoomApiGetOptions<
  TOpenApiPath extends OpenApiPathWith<paths, "get">
> = UseOpenApiGetOptions<paths, TOpenApiPath>;

export default useDealRoomApiGet;

export type {UseDealRoomApiGetOptions};
