import camelcaseKeys from "camelcase-keys";
import {useMemo} from "react";

import useDealRoomApiGet from "./use-deal-room-api-get";
import type {UseDealRoomApiGetOptions} from "./use-deal-room-api-get";

const getMemberEndpoint = "/member/{member-id}";

type UseMemberOptions = UseDealRoomApiGetOptions<typeof getMemberEndpoint>;

const useMember = (memberId: number, options?: UseMemberOptions) => {
  const memberResponse = useDealRoomApiGet(
    getMemberEndpoint,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "member-id": memberId
    },
    options
  );

  const member = useMemo(
    () =>
      memberResponse.data?.data &&
      camelcaseKeys(memberResponse.data.data, {deep: true}),
    [memberResponse.data]
  );

  return {member, refreshMember: memberResponse.mutate};
};

export default useMember;
