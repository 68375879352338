import type {CamelCaseKeys} from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import type {components} from "./deal-room-api-schema";
import useDealRoomApiMutate from "./use-deal-room-api-mutate";

type DealUpdateInput = CamelCaseKeys<
  components["schemas"]["UpdateDealInput"],
  true
>;

const useDealUpdate = (dealId: number) => {
  const dealRoomApiMutate = useDealRoomApiMutate();

  const dealUpdate = (input: DealUpdateInput) =>
    dealRoomApiMutate("/deal/{deal-id}", "patch", {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "deal-id": dealId,
      ...snakecaseKeys(input)
    });

  return dealUpdate;
};

export default useDealUpdate;
