import {create} from "zustand";
import {persist} from "zustand/middleware";

import {propertyTypeFilters} from "@/utils/deal-room-api/property-type-filter";
import type {PropertyTypeFilter} from "@/utils/deal-room-api/property-type-filter";

type State = {
  propertyTypeFilter: PropertyTypeFilter;
  setPropertyTypeFilter: (propertyTypeFilter: PropertyTypeFilter) => unknown;
};

const useStore = create<State>()(
  persist(
    set => ({
      propertyTypeFilter: propertyTypeFilters.all,

      setPropertyTypeFilter: propertyTypeFilter =>
        set(() => ({propertyTypeFilter}))
    }),
    {
      name: "store"
    }
  )
);
export default useStore;
