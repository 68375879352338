import camelcaseKeys from "camelcase-keys";
import {useMemo} from "react";

import useDealRoomApiGet from "./use-deal-room-api-get";
import type {UseDealRoomApiGetOptions} from "./use-deal-room-api-get";

const getPropertyEndpoint = "/deal/{deal-id}/properties";

type UsePropertyOptions = UseDealRoomApiGetOptions<typeof getPropertyEndpoint>;

const useProperty = (dealId?: number, options?: UsePropertyOptions) => {
  const propertyResponse = useDealRoomApiGet(
    getPropertyEndpoint,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "deal-id": dealId ?? 0
    },
    {...options, skip: dealId === undefined || options?.skip}
  );

  const property = useMemo(
    () =>
      propertyResponse.data?.data &&
      camelcaseKeys(propertyResponse.data.data, {deep: true}),
    [propertyResponse.data]
  );

  return {property, refreshProperty: propertyResponse.mutate};
};

export default useProperty;

export type {UsePropertyOptions};
