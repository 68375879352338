import {useState} from "react";

import type {components} from "./deal-room-api-schema";

type DirectoryFolder = components["schemas"]["DirectoryFolder"];

const useDirectoryNavigation = () => {
  const [currentFolder, setCurrentFolder] = useState<
    DirectoryFolder | undefined
  >(undefined);

  const [parentFolders, setParentFolders] = useState<DirectoryFolder[]>([]);

  const updateParentFolders = (directoryFolder?: DirectoryFolder) => {
    if (directoryFolder) {
      const existingFolderIndex = parentFolders.findIndex(
        accessedFolder => accessedFolder.path === directoryFolder.path
      );

      if (existingFolderIndex !== -1) {
        setParentFolders(prevAccessedFolders =>
          prevAccessedFolders.slice(0, existingFolderIndex)
        );
      } else if (currentFolder) {
        setParentFolders(prevAccessedFolders => [
          ...prevAccessedFolders,
          currentFolder
        ]);
      }
    } else {
      setParentFolders([]);
    }
  };

  const moveToFolder = (directoryFolder?: DirectoryFolder) => {
    setCurrentFolder(directoryFolder);

    updateParentFolders(directoryFolder);
  };

  const currentPath = currentFolder?.path ?? "";

  return {
    currentFolder,
    currentPath,
    moveToFolder,
    parentFolders
  };
};

export default useDirectoryNavigation;
