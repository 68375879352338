import type {components} from "./deal-room-api-schema";
import {propertyTypes} from "./property-type";
import type {PropertyType} from "./property-type";
import useDealRoomApiGet from "./use-deal-room-api-get";
import type {UseDealRoomApiGetOptions} from "./use-deal-room-api-get";

const getVerticalsEndpoint = "/verticals";

type UseVerticalNamesOptions = UseDealRoomApiGetOptions<
  typeof getVerticalsEndpoint
>;

type Vertical = components["schemas"]["Vertical"];

const verticalFilters = {
  [propertyTypes.medical]: (vertical: Vertical) =>
    vertical.name !== "MULTIFAMILY",
  [propertyTypes.multifamily]: (vertical: Vertical) =>
    vertical.name === "MULTIFAMILY"
};

const filterVerticals = (verticals: Vertical[], propertyType: PropertyType) =>
  verticalFilters[propertyType]
    ? verticals.filter(verticalFilters[propertyType])
    : verticals;

const useVerticalNames = (
  propertyType: PropertyType,
  options?: UseVerticalNamesOptions
) => {
  const verticalsResponse = useDealRoomApiGet(
    getVerticalsEndpoint,
    {},
    options
  );

  const verticalNames = verticalsResponse.data?.data
    ? filterVerticals(verticalsResponse.data.data, propertyType).map(
        vertical => vertical.name
      )
    : [];

  return verticalNames;
};

export default useVerticalNames;
