import * as withOpenApiClient from "@unlockre/open-api-client/dist";

import auth0Client from "../auth0/auth0-client";
import environmentVariables from "../environment/environment-variables";

import type {paths} from "./estimate-api-schema";

const estimateApiClient = withOpenApiClient.create<paths>({
  baseUrl: environmentVariables.ESTIMATE_API_URL,
  use: [withOpenApiClient.createAuth0Middleware(auth0Client)]
});

export default estimateApiClient;
