import * as z from "zod";

const memberTypes = {
  buyer: "BUYER",
  buyerCounsel: "BUYER_COUNSEL",
  seller: "SELLER",
  sellerBroker: "SELLER_BROKER",
  sellerCounsel: "SELLER_COUNSEL",
  sellerSupport: "SELLER_SUPPORT"
} as const;

type MemberType = (typeof memberTypes)[keyof typeof memberTypes];

const memberTypeSchema = z.enum([
  memberTypes.buyer,
  memberTypes.buyerCounsel,
  memberTypes.seller,
  memberTypes.sellerBroker,
  memberTypes.sellerCounsel,
  memberTypes.sellerSupport
]);

// eslint-disable-next-line complexity
const getDescription = (memberType: MemberType) => {
  switch (memberType) {
    case memberTypes.buyerCounsel:
      return "Buyer's counsel";

    case memberTypes.buyer:
      return "Buyer";

    case memberTypes.sellerBroker:
      return "Seller's broker";

    case memberTypes.sellerCounsel:
      return "Seller's counsel";

    case memberTypes.sellerSupport:
      return "Seller's support";

    case memberTypes.seller:
      return "Seller";
  }
};

// eslint-disable-next-line complexity
const getLabel = (memberType: MemberType) => {
  switch (memberType) {
    case memberTypes.buyerCounsel:
      return "Buyer Counsel";

    case memberTypes.buyer:
      return "Buyer";

    case memberTypes.sellerBroker:
      return "Seller Broker";

    case memberTypes.sellerCounsel:
      return "Seller Counsel";

    case memberTypes.sellerSupport:
      return "Seller Support";

    case memberTypes.seller:
      return "Seller";
  }
};

const isBuyer = (memberType: MemberType) => memberType === memberTypes.buyer;
const isBuyerCounsel = (memberType: MemberType) =>
  memberType === memberTypes.buyerCounsel;

export type {MemberType};

export {
  getDescription,
  getLabel,
  isBuyer,
  isBuyerCounsel,
  memberTypes,
  memberTypeSchema
};
