import camelcaseKeys from "camelcase-keys";
import {useMemo} from "react";

import useDealRoomApiGet from "./use-deal-room-api-get";
import type {UseDealRoomApiGetOptions} from "./use-deal-room-api-get";

const getLeaseEndpoint = "/deal/{deal-id}/lease";

type UseLeaseOptions = UseDealRoomApiGetOptions<typeof getLeaseEndpoint>;

const useLease = (dealId: number, options?: UseLeaseOptions) => {
  const leaseResponse = useDealRoomApiGet(
    getLeaseEndpoint,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "deal-id": dealId
    },
    options
  );

  const lease = useMemo(
    () =>
      leaseResponse.data?.data &&
      camelcaseKeys(leaseResponse.data.data, {deep: true}),
    [leaseResponse.data]
  );

  return {lease, refreshLease: leaseResponse.mutate};
};

export default useLease;

export type {UseLeaseOptions};
