import {z} from "zod";

const propertyTypes = {
  medical: "MEDICAL",
  multifamily: "MULTIFAMILY"
} as const;

type PropertyType = (typeof propertyTypes)[keyof typeof propertyTypes];

const propertyTypeSchema = z.enum([
  propertyTypes.medical,
  propertyTypes.multifamily
]);

const ensureIsPropertyType = (value: string) => {
  if (!isPropertyType(value)) {
    throw new Error(`Invalid property type: ${value}`);
  }

  return value;
};

const isMedicalOffice = (propertyType: PropertyType) =>
  propertyType === propertyTypes.medical;

const isMultifamily = (propertyType: PropertyType) =>
  propertyType === propertyTypes.multifamily;

const isPropertyType = (value: string): value is PropertyType =>
  (Object.values(propertyTypes) as string[]).includes(value);

const propertyTypeDescriptions = {
  [propertyTypes.medical]: "Medical Office",
  [propertyTypes.multifamily]: "Multifamily"
};

const getDescription = (propertyType: PropertyType) =>
  propertyTypeDescriptions[propertyType];

export {
  ensureIsPropertyType,
  getDescription,
  isMedicalOffice,
  isMultifamily,
  isPropertyType,
  propertyTypes,
  propertyTypeSchema
};

export type {PropertyType};
