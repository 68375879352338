const dealRoomApiPermissions = {
  dealRoomDashboardReadAll: "deal_room:read_all:dashboard",
  dealRoomDashboardReadOwn: "deal_room:read_own:dashboard",
  dealRoomDealsCreate: "deal_room:create:deals",
  dealRoomDealsReadAll: "deal_room:read_all:deals",
  dealRoomDealsReadOwn: "deal_room:read_own:deals",
  dealRoomDealsUpdateAll: "deal_room:update_all:deals",
  dealRoomDealsUpdateOwn: "deal_room:update_own:deals",
  dealRoomMembersCreate: "deal_room:create:members",
  dealRoomMembersRead: "deal_room:read:members",
  dealRoomMembersUpdate: "deal_room:update:members",
  dealRoomUsersCreate: "deal_room:create:users"
} as const;

type DealRoomApiPermission =
  (typeof dealRoomApiPermissions)[keyof typeof dealRoomApiPermissions];

export {dealRoomApiPermissions};

export type {DealRoomApiPermission};
