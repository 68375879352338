import {
  getColorByAlias,
  getResponsiveCss,
  useThemeBreakpoint
} from "@unlockre/components-library/dist/theme-provider/theme";
import {
  KeyosPrivatePageLayout,
  bottomMobileNavbarHeight,
  topMobileNavbarHeight
} from "@unlockre/keyos-tools/dist/keyos-private-page-layout";
import type {ReactNode} from "react";
import styled, {css} from "styled-components";

import environmentVariables from "@/utils/environment/environment-variables";
import {useUserSplitIoTreatments} from "@/utils/split-io";

import PrivatePageNavBar from "./private-page-nav-bar";
import SmallScreenMessage from "./small-screen-page";

type Props = {
  children: ReactNode;
};

const featureFlagNames = ["showMobileApp"] as const;

const Container = styled.div`
  background-color: ${getColorByAlias("backgroundTertiary")};
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${getResponsiveCss(
    "mobile",
    css`
      min-height: calc(
        100vh - ${bottomMobileNavbarHeight + topMobileNavbarHeight}px
      );
    `
  )}
`;

const PrivatePageLayout = ({children}: Props) => {
  const userSplitIoTreatments = useUserSplitIoTreatments(featureFlagNames);

  const showMobileApp =
    userSplitIoTreatments?.showMobileApp?.treatment === "on";

  const isViewportWiderThanLaptop = !useThemeBreakpoint("laptop");

  const canSeeContent = showMobileApp || isViewportWiderThanLaptop;

  return (
    <KeyosPrivatePageLayout
      assetManagerUrl={environmentVariables.ASSET_MANAGER_APP_URL}
      dealRoomAdminUrl={environmentVariables.DEAL_ROOM_ADMIN_APP_URL}
      keybrainUrl={environmentVariables.KEYBRAIN_APP_URL}
      keycompsUrl={environmentVariables.KEYCOMPS_APP_URL}
      keydocsUrl={environmentVariables.KEYDOCS_APP_URL}
      propertySageUrl={environmentVariables.PROPERTY_SAGE_APP_URL}
    >
      {canSeeContent ? (
        <Container>
          <PrivatePageNavBar />
          {children}
        </Container>
      ) : (
        <SmallScreenMessage />
      )}
    </KeyosPrivatePageLayout>
  );
};

export default PrivatePageLayout;
