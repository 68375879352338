import {useAnyOpenApiClient} from "@unlockre/open-api-client/dist/open-api-clients-context";

import dealRoomApiName from "./deal-room-api-name";
import type {DealRoomApiClient} from "./types";

const useDealRoomApiClient = <
  TOpenApiClientRecord extends {[dealRoomApiName]: DealRoomApiClient}
>() =>
  useAnyOpenApiClient<TOpenApiClientRecord, typeof dealRoomApiName>(
    dealRoomApiName
  );

export default useDealRoomApiClient;
