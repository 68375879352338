type Headers = Record<string, string>;

type UploadFileHTTPMethod = "POST" | "PUT";

type Params = Record<string, Blob | string>;

type UploadFileParams = {
  headers?: Headers;
  method: UploadFileHTTPMethod;
  params: Params;
  url: string;
};

const getFormData = (params: Params) =>
  Object.entries(params).reduce((formData, [key, value]) => {
    formData.append(key, value);

    return formData;
  }, new FormData());

const uploadFile = ({headers, method, params, url}: UploadFileParams) =>
  fetch(url, {
    headers,
    method,
    body: getFormData(params)
  });

export default uploadFile;
