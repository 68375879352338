import {bindUseOpenApiGet} from "@unlockre/open-api-client/dist";

import {swrWithCustomResponse} from "../swr";
import * as withSwrResponse from "../swr/swr-response";

import useEstimateApiClient from "./use-estimate-api-client";

const useEstimateApiGet = swrWithCustomResponse(
  bindUseOpenApiGet(useEstimateApiClient),
  withSwrResponse.throwIfError
);

export default useEstimateApiGet;
