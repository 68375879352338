import {useAuth0AccessTokenClaims} from "@/utils/auth0";
import {dealRoomPermissions} from "@/utils/auth0/deal-room-permission";
import type {DealRoomPermission} from "@/utils/auth0/deal-room-permission";

import useIsUserDealMember from "./use-is-user-deal-member";

type DealPermissions = {
  canReadDeal: boolean;
  canUpdateDeal: boolean;
};

type OwnDealsPermission =
  | typeof dealRoomPermissions.dealRoomDealsReadOwn
  | typeof dealRoomPermissions.dealRoomDealsUpdateOwn;

type AllDealsPermission =
  | typeof dealRoomPermissions.dealRoomDealsReadAll
  | typeof dealRoomPermissions.dealRoomDealsUpdateAll;

const canDoDealAction = (
  userPermissions: DealRoomPermission[],
  isUserDealMember: boolean,
  allDealsPermission: AllDealsPermission,
  ownDealsPermission: OwnDealsPermission
) => {
  const canDoOwnDealsAction =
    userPermissions.includes(ownDealsPermission) && isUserDealMember;

  const canDoAllDealsAction = userPermissions.includes(allDealsPermission);

  return canDoAllDealsAction || canDoOwnDealsAction;
};

const useDealPermissions = (dealId: number): DealPermissions | undefined => {
  const accessTokenClaims = useAuth0AccessTokenClaims();

  const isUserDealMember = useIsUserDealMember(dealId);

  if (
    accessTokenClaims.status !== "succeeded" ||
    isUserDealMember === undefined
  ) {
    return undefined;
  }

  const userPermissions = accessTokenClaims.data.permissions;

  return {
    canReadDeal: canDoDealAction(
      userPermissions,
      isUserDealMember,
      dealRoomPermissions.dealRoomDealsReadAll,
      dealRoomPermissions.dealRoomDealsReadOwn
    ),
    canUpdateDeal: canDoDealAction(
      userPermissions,
      isUserDealMember,
      dealRoomPermissions.dealRoomDealsUpdateAll,
      dealRoomPermissions.dealRoomDealsUpdateOwn
    )
  };
};

export default useDealPermissions;

export type {DealPermissions};
