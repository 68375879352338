import {
  getColorByAlias,
  getTypography
} from "@unlockre/components-library/dist/theme-provider/theme";
import {
  UnstyledH2,
  UnstyledParagraph
} from "@unlockre/components-library/dist/unstyled";
import styled from "styled-components";

const Message = styled(UnstyledParagraph)`
  ${getTypography("body", "l")}
  color: ${getColorByAlias("textPrimary")};
  text-align: center;
`;

const Title = styled(UnstyledH2)`
  ${getTypography("title", "xl")}

  color: ${getColorByAlias("textPrimary")};
  text-align: center;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  padding: 0 24px;
`;

const SmallScreenMessage = () => (
  <Container>
    <Title>Screen Size Limitation</Title>
    <Message>
      Please use a device with a larger screen (resolution width of 1024 px or
      more) for the best experience with DealRoom.
    </Message>
    <Message>
      We are actively working to enhance your experience. Your patience and
      feedback are greatly appreciated.
    </Message>
  </Container>
);

export default SmallScreenMessage;
