const dealTeams = {
  buyer: "BUYER",
  seller: "SELLER"
} as const;

type DealTeam = (typeof dealTeams)[keyof typeof dealTeams];

const isSeller = (dealTeam: DealTeam) => dealTeam === dealTeams.seller;

const isBuyer = (dealTeam: DealTeam) => dealTeam === dealTeams.buyer;

const ensureIsBuyer = (dealTeam: DealTeam) => {
  if (!isBuyer(dealTeam)) {
    throw new Error(
      `Expected deal team to be ${dealTeams.buyer}, but instead got: ` +
        dealTeam
    );
  }

  return dealTeam;
};

const getDescription = (dealTeam: DealTeam) => {
  switch (dealTeam) {
    case dealTeams.buyer:
      return "Buyer";

    case dealTeams.seller:
      return "Seller";
  }
};

export {dealTeams, ensureIsBuyer, getDescription, isBuyer, isSeller};

export type {DealTeam};
